import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image";
import { widthInfo } from "../../util/index.js"

const Upaisa = () => {
  const PictureData = useStaticQuery(graphql`
    query UpaisaQuery {
      Upaisa3: file(relativePath: { eq: "Upaisa/Upaisa3.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Upaisa4: file(relativePath: { eq: "Upaisa/Upaisa4.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Upaisa5: file(relativePath: { eq: "Upaisa/Upaisa5.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Upaisa6: file(relativePath: { eq: "Upaisa/Upaisa6.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Upaisa7: file(relativePath: { eq: "Upaisa/Upaisa7.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Upaisa8: file(relativePath: { eq: "Upaisa/Upaisa8.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      
    }
  `)
  let width = widthInfo()
  const { Upaisa3, Upaisa4, Upaisa5, Upaisa6, Upaisa7, Upaisa8 } = PictureData;

  return (
    <>

<div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>Go to the UPaisa App and tap on the search icon on the bottom right corner of your App. </span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Upaisa3.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}04</span>
          <span className={styles.contentTitleText}>Type “1Bill” in the search bar and select “1Bill” from the search results.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={Upaisa4.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}05</span>
          <span className={styles.contentTitleText}>Tap on “Voucher Payment” button.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={Upaisa5.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}06</span>
          <span className={styles.contentTitleText}>Enter your Barwaqt “Token Number” and tap on the “Submit” button.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={Upaisa6.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}07</span>
          <span className={styles.contentTitleText}>Tap on the “Confirm” button to pay your loan.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Upaisa7.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}08</span>
          <span className={styles.contentTitleText}>Congratulations! Your loan amount has been paid. You will receive a confirmation SMS on your mobile phone. You can see your order has been settled on the Barwaqt App.</span>
        </div>
        <Img
          className={styles.pict312}
          fluid={Upaisa8.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />

      </div>
    </>

  )
}

export default Upaisa;
