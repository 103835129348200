import * as React from "react"
import * as styles from './css/oneBill.module.styl'
import Layout from "../components/layout";
import { widthInfo } from "../util/index.js"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby";
import Abl from "../components/ABL/ABL"
import Ubl from "../components/UBL/UBL"
import Meezan from "../components/Meezan/Meezan"
import Upaisa from "../components/Upaisa/Upaisa"
import JazzCash from "../components/JazzCash/JazzCash"
import Easypaisa from "../components/Easypaisa/Easypaisa"
let width = widthInfo()
import activeLine from '../images/paymentVia/activeLine.png'
import upSanjiao from '../images/oneBill/upSanjiao.png'
import downSanjiao from '../images/oneBill/downSanjiao.png'
import sanjiao from '../images/oneBill/sanjiao@2x.png'
import bannerPicture from '../images/paymentVia/bannerbg.png'
import all01 from '../images/ABL/ABL1.png'
import all02 from '../images/ABL/ABL2.png'
class OneBillPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  get initialState() {
    return {
      scrollTop: 582,
      OneBillList: [
        { title: 'Repayment Via 1Bill-ABL', isFolded: false, id: "ABL" },
        { title: 'Repayment Via 1Bill-UBL', isFolded: false, id: "UBL" },
        { title: 'Repayment Via 1Bill-Meezan', isFolded: false, id: "Meezan" },
        { title: 'Repayment Via 1Bill-Upaisa', isFolded: false, id: "Upaisa" },
        { title: 'Repayment Via 1Bill-JazzCash', isFolded: false, id: "JazzCash" },
        { title: 'Repayment via 1Bill-Easypaisa', isFolded: false, id: "Easypaisa" },
      ],
      hasVerticalScrolled: false,
    }
  }
  //在componentDidMount，进行scroll事件的注册，绑定一个函数，让这个函数进行监听处理   
  componentDidMount() { window.addEventListener('scroll', this.bindHandleScroll) }
  //在componentWillUnmount，进行scroll事件的注销   
  componentWillUnmount() { window.removeEventListener('scroll', this.bindHandleScroll); }
  bindHandleScroll = (event) => {
    // 滚动的高度       
    const top = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    this.setState({
      hasVerticalScrolled: top > 582
    })
  }

  gotop = () => {
    let hastrue =false
    this.state.OneBillList.map((item, index) => {
      if (item.isFolded) {
        hastrue = true
      }
      return item
    }); 
    if(hastrue){
      document.documentElement.scrollTop = this.state.scrollTop
    }else{
      document.documentElement.scrollTop = 0
    }
         
  }
  handleChangeList = (ind) => { 
    let top,scrollToHeight 
    this.state.OneBillList.map((item, index) => {
      if (ind === index) {
        item.isFolded = !item.isFolded;
        index >2 ? top = 653 : top = 582
        scrollToHeight = top + (index * 106)
        console.log('scrollToHeighttttttttttttttttttttttt'+ scrollToHeight)
        this.setState({
          scrollTop:scrollToHeight
        }) 
      } else {
        item.isFolded = false;
      }
      return item; 
    });
    this.setState({
      OneBillList: this.state.OneBillList
    });

  };
  render() {
    return (
      <Layout>
        <Seo title="BarwaqtRepaymentMethods"></Seo>
        <header className={styles.paybanner}>
          <div className={styles.paybannercenter}>
            <div className={styles.paybannerInfo}>
              {width > 1024 ?
                <div className={styles.paybannerInfo}>
                  <div className={styles.paybannerTitle}>
                    Barwaqt
                    <br />
                    Repayment Methods
                  </div>
                  <img
                    className={styles.paybannerLogo}
                    src={bannerPicture}
                    alt="A corgi smiling happily" />
                </div>
                :
                <img
                  src={bannerPicture}
                  className={styles.paybannerLogo}
                  alt="A corgi smiling happily" />

              }
            </div >
          </div >
        </header >
        {width > 1024 ?
          // pc
          <div className={styles.paynav}>
            <div className={styles.payNav80}>
              <li className={styles.payli} >
                <div className={styles.payliInfo}>
                  <span className={styles.active}>   Repayment Via 1Bill </span>
                  <img
                    className={styles.paybottomLine}
                    src={activeLine}
                    alt="A corgi smiling happily"
                  />
                </div>
              </li>
              <li className={styles.payli} >
                <div className={styles.payliInfo}>
                  <span>  <Link to='/repaymentViabyWa'>Repayment Via Mobile Wallet</Link></span>

                </div>
              </li>
              <li className={styles.payli} >
                <div className={styles.payliInfo}>
                  <span >   <Link to='/RepaymentByEasypaisa'> Repayment Via Easypaisa</Link></span>
                </div>

              </li>
            </div>
          </div>
          :
          // H5
          <div className={styles.paynav}>
            <div className={styles.payNav80}>
              <div className={`${styles.payli}  ${styles.payliInfo} ${styles.payliRightLine}`} >
                <span >1Bill</span>
              </div>
              <div className={`${styles.payli} ${styles.payliInfo}`}>
                <span >  <Link to='/repaymentViabyWa'> MOBILE WALLET   </Link></span>
              </div>
              <div className={`${styles.payli} ${styles.payliInfo}`}>
                <span>  <Link to='/RepaymentByEasypaisa'> EASYPAISA  </Link></span>
              </div>
            </div>
          </div>
        }
        <div className={styles.oneBill}>
          {this.state.OneBillList.map((item, index) => (
            <div role="presentation" 
              key={index} >
              <div className={`${styles.bigTitle} ${item.id === 'ABL' ? 'block' : 'none'}`}  >Repayment method using Bank</div>
              <div className={`${styles.bigTitle} ${item.id === 'Upaisa' ? 'block' : 'none'}`}  >Repayment method using mobile wallet</div>
              <div className={styles.presentation} id={item.id} >
              <div className={styles.oneBorder} >
                {/* className={styles.presentation} */}
               <a href={'#'+ item.id } className={styles.presentation}>
                <div className={styles.oneTitle} onClick={() => this.handleChangeList(index)}>
                  <div className={styles.left}>
                    <img
                      className={styles.leftSanjiao}
                      src={sanjiao}
                      alt="A corgi smiling happily" />
                    <span>{item.title}</span>
                  </div>
                  <div className={`${styles.right} ${item.isFolded === true ? 'block' : 'none'}`}>
                    <img
                      src={upSanjiao}

                      alt="A corgi smiling happily" />
                  </div>
                  <div className={`${styles.right} ${item.isFolded === true ? 'none' : 'block'}`}>
                    <img
                      src={downSanjiao}

                      alt="A corgi smiling happily" />
                  </div>
                </div>
                </a> 
                <div className={`${styles.bottomLine} ${item.isFolded === true ? 'block' : 'none'}`}></div>
                <div className={`${styles.content} ${item.isFolded === true ? 'block' : 'none'}`}>
                  <div className={styles.contentTitle}>
                    <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}01</span>
                    <span className={styles.contentTitleText}>Go to the Repayment Methods on your Barwaqt App and choose Repayment via 1Bill.</span>
                  </div>
                  <img
                    className={styles.pict525}
                    src={all01}

                    alt="A corgi smiling happily"
                  />
                  <div className={styles.contentTitle}>
                    <span className={styles.contentTitleIcon}>{width > 1024 ? <span>STEP </span> : null} 02</span>
                    <span className={styles.contentTitleText}>Your token is your Unique Id which will be required in order to pay your loan amount. You may copy your token by clicking the “Copy” button.</span>
                  </div>
                  <img
                    className={styles.pict481}

                    src={all02}
                    alt="A corgi smiling happily"
                  />
                </div>
                <div className={`${item.isFolded === true && item.id === 'ABL' ? 'block' : 'none'}`}><Abl></Abl></div>
                <div className={`${item.isFolded === true && item.id === 'UBL' ? 'block' : 'none'}`}><Ubl></Ubl></div>
                <div className={`${item.isFolded === true && item.id === 'Meezan' ? 'block' : 'none'}`}><Meezan></Meezan></div>
                <div className={`${item.isFolded === true && item.id === 'Upaisa' ? 'block' : 'none'}`}><Upaisa></Upaisa></div>
                <div className={`${item.isFolded === true && item.id === 'JazzCash' ? 'block' : 'none'}`}><JazzCash></JazzCash></div>
                <div className={`${item.isFolded === true && item.id === 'Easypaisa' ? 'block' : 'none'}`}><Easypaisa></Easypaisa></div>

              </div>
</div>
            </div >
          ))}
        </div>
        {
        width > 1024 ?  
        <div className={`${this.state.hasVerticalScrolled ? 'block' : 'none'} ${styles.topTipsContent}` }> 
          <div className={styles.topTips}>Return to top</div>
          <div onClick={() => this.gotop()} className={`${this.state.hasVerticalScrolled ? 'block' : 'none'} ${styles.upButton}`} > </div> 
        </div> : null
      }
      </Layout>)
  }

}

export default OneBillPage
