import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import { widthInfo } from "../../util/index.js"


let width = widthInfo()
const Ubl = ({ siteTitle }) => {
  const PictureData = useStaticQuery(graphql`
    query UblQuery {

      UBL311: file(relativePath: { eq: "UBL/UBL311.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL312: file(relativePath: { eq: "UBL/UBL312.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL313: file(relativePath: { eq: "UBL/UBL313.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL314: file(relativePath: { eq: "UBL/UBL314.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL315: file(relativePath: { eq: "UBL/UBL315.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL316: file(relativePath: { eq: "UBL/UBL316.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL317: file(relativePath: { eq: "UBL/UBL317.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL321: file(relativePath: { eq: "UBL/UBL321.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL322: file(relativePath: { eq: "UBL/UBL322.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      UBL323: file(relativePath: { eq: "UBL/UBL323.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      UBL324: file(relativePath: { eq: "UBL/UBL324.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      UBL325: file(relativePath: { eq: "UBL/UBL325.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      UBL326: file(relativePath: { eq: "UBL/UBL326.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      UBL327: file(relativePath: { eq: "UBL/UBL327.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,   
        
    }
  `)
  const { UBL311, UBL312, UBL313, UBL314, UBL315, UBL316, UBL317, UBL321, UBL322, UBL323, UBL324, UBL325, UBL326, UBL327 } = PictureData;

  return (
    <>

      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>There are two methods to pay your loan amount using UBL.</span>
        </div>
        <div className={styles.foursamllPart}>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>“UBL Digital” Mobile App</span>
          </div>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>UBL Website</span>
          </div>
          <div className={styles.height38}></div>
          {/* 01 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>UBL Digital Mobile App</span>
          </div>
          <div className={styles.fourthredPart}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Go to the UBL Digital App and tap the “Payments” on your App.</span>
            </div>
            <Img
              className={styles.pict404}
              fluid={UBL311.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>On the Payments page, tap the “Government & Online Payments”.</span>
            </div>
            <Img
              className={styles.pict416}
              fluid={UBL312.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Tap “1BILL” in the Online Payments section.</span>
            </div>
            <Img
              className={styles.pict320}
              fluid={UBL313.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>On the 1Bill Payment page, tap the “Select Payment Type”.</span>
            </div>
            <Img
              className={styles.pict462}
              fluid={UBL314.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>On the Select Payment Type page, choose “Invoice/Voucher”.</span>
            </div>
            <Img
              className={styles.pict462}
              fluid={UBL315.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Enter your Barwaqt “Token Number” and tap the “Next” button.</span>
            </div>
            <Img
              className={styles.pict525}
              fluid={UBL316.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Enter the OTP, and tap “Pay” to make the loan re-payment successful.</span>
            </div>
            <Img
              className={styles.pict525}
              fluid={UBL317.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          {/* 02 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>UBL Website</span>
          </div>
        </div>
      </div>

      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`} >
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Go to the URL <a className={styles.href} href="https://www.ubldirect.com/gnb/corplogin.aspx"  target="_blank"> https://www.ubldirect.com/gnb/corplogin.aspx </a> and “Login” to your UBL account using your credentials. </span>
      </div>
      <Img
        className={styles.pict340}
        fluid={UBL321.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Enter the OTP and click the "Confirm" button.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL322.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>On the home page of your UBL website, click “Business Payments” in My Payments section.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL323.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>In the Other Payments section, Go to the “1BILL” option and click “Pay”</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL324.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Select “Invoice/Voucher” option in the Payment Type. Now enter your Barwaqt “Token Number” in the Consumer ID and click “Display Detail”</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL325.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>You can see the detailed inquiry of the payment. Click “Pay” to proceed.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL326.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Enter the OTP and click the “Confirm” button.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={UBL327.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
    </>
  )
}

export default Ubl
