import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image";
import { widthInfo } from "../../util/index.js"

const Easypaisa = () => {
  const PictureData = useStaticQuery(graphql`
    query EasypaisaQuery {
      Easypaisa3: file(relativePath: { eq: "Easypaisa/Easypaisa3.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa4: file(relativePath: { eq: "Easypaisa/Easypaisa4.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa5: file(relativePath: { eq: "Easypaisa/Easypaisa5.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa6: file(relativePath: { eq: "Easypaisa/Easypaisa6.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa7: file(relativePath: { eq: "Easypaisa/Easypaisa7.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa8: file(relativePath: { eq: "Easypaisa/Easypaisa8.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa9: file(relativePath: { eq: "Easypaisa/Easypaisa9.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa10: file(relativePath: { eq: "Easypaisa/Easypaisa10.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Easypaisa11: file(relativePath: { eq: "Easypaisa/Easypaisa11.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
    }
  `)
  let width = widthInfo()
  const { Easypaisa3, Easypaisa4, Easypaisa5, Easypaisa6, Easypaisa7, Easypaisa8, Easypaisa9, Easypaisa10, Easypaisa11 } = PictureData;

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>Go to the Easypaisa App and tap on the search icon on the top right corner of your App.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa3.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}04</span>
          <span className={styles.contentTitleText}>Type “1 Bill” in the search bar and select “1 Bill Invoice/Voucher” from the search results.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={Easypaisa4.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}05</span>
          <span className={styles.contentTitleText}>Tap the “Enter a new bill to pay”.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={Easypaisa5.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}06</span>
          <span className={styles.contentTitleText}>From the “Select Bill Type” list, tap the “Others”.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa6.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}07</span>
          <span className={styles.contentTitleText}>From the “Select Company” list, tap the “1 Bill Invoice/Voucher”.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa7.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}08</span>
          <span className={styles.contentTitleText}>Enter your Barwaqt “Token Number” and tap the “NEXT” button.</span>
        </div>
        <Img
          className={styles.pict462}
          fluid={Easypaisa8.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}09</span>
          <span className={styles.contentTitleText}>Tap the “Pay Now” button to pay your loan.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa9.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}10</span>
          <span className={styles.contentTitleText}>Congratulations! Your loan amount has been paid. You will receive a transaction successful receipt.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa10.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}11</span>
          <span className={styles.contentTitleText}>Finally, you can see your order has been settled on the Barwaqt App.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={Easypaisa11.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
      </div>
    </>

  )
}

export default Easypaisa;
