import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image";
import { widthInfo } from "../../util/index.js"

const Abl = () => {
  const PictureData = useStaticQuery(graphql`
    query AblQuery {
      ABL311: file(relativePath: { eq: "ABL/ABL311.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL312: file(relativePath: { eq: "ABL/ABL312.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL313: file(relativePath: { eq: "ABL/ABL313.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL314: file(relativePath: { eq: "ABL/ABL314.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL315: file(relativePath: { eq: "ABL/ABL315.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL316: file(relativePath: { eq: "ABL/ABL316.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL317: file(relativePath: { eq: "ABL/ABL317.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL321: file(relativePath: { eq: "ABL/ABL321.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL322: file(relativePath: { eq: "ABL/ABL322.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      ABL323: file(relativePath: { eq: "ABL/ABL323.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      ABL324: file(relativePath: { eq: "ABL/ABL324.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      ABL325: file(relativePath: { eq: "ABL/ABL325.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      ABL326: file(relativePath: { eq: "ABL/ABL326.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      ABL327: file(relativePath: { eq: "ABL/ABL327.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,   
    }
  `)
  let width = widthInfo()
  const { ABL311, ABL312, ABL313, ABL314, ABL315, ABL316, ABL317, ABL321, ABL322, ABL323, ABL324, ABL325, ABL326, ABL327 } = PictureData;

  return (
    <>

      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>There are two methods to pay your loan amount using ABL.</span>
        </div>
        <div className={styles.foursamllPart}>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>“MyABL” Mobile App</span>
          </div>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>ABL Website</span>
          </div>
          <div className={styles.height38}></div>
          {/* 01 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>MyABL Mobile App</span>
          </div>
          <div className={styles.fourthredPart}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Go to the MyABL App and tap the “Payments” option on your App.</span>
            </div>
            <Img
              className={styles.pict422}
              fluid={ABL311.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>On the Bill Payments page, tap the “New Payments”.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={ABL312.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Type “1 Bill” in the search bar and select “1 Bill (Invoices and Topup)” from the search results.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={ABL313.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Choose “Others” from the Select Biller list.</span>
            </div>
            <Img
              className={styles.pict320}
              fluid={ABL314.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Enter your Barwaqt “Token Number” and tap the “Proceed” button.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={ABL315.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Tap the “Pay” button to pay your loan.</span>
            </div>
            <Img
              className={styles.pict372}
              fluid={ABL316.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Tap the “Confirm” button, enter the OTP, and tap “Submit” to make the loan re-payment successful.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={ABL317.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          {/* 02 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>ABL Website</span>
          </div>
        </div>
      </div>

      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`} >
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Go to the URL <a className={styles.href} target="_blank" href="https://login.abl.com"> https://login.abl.com </a> and “Sign In” to your ABL account using your credentials.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL321.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>On the home page of your ABL website, click “Bill Payments”.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL322.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>On the Bill Payments page, choose the “New Biller” option in the Payment Type.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL323.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Select “1 Bill (Invoices and Topup)” option in the Category and “Others” in the New Biller Name. Now enter your Barwaqt “Token Number” in the Reference No/Consumer ID.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL324.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Click the “Validate” button for the detailed inquiry of the payment and then click “Pay” to proceed.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL325.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Review your payment details here and click “Confirm”.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL326.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Enter the OTP and click “Submit” button.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={ABL327.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />




    </>

  )
}

export default Abl;
