import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image";
import { widthInfo } from "../../util/index.js"

const Meezan = () => {
  const PictureData = useStaticQuery(graphql`
  query MeezanQuery {
      Meezan311: file(relativePath: { eq: "Meezan/Meezan311.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan312: file(relativePath: { eq: "Meezan/Meezan312.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan313: file(relativePath: { eq: "Meezan/Meezan313.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan314: file(relativePath: { eq: "Meezan/Meezan314.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan315: file(relativePath: { eq: "Meezan/Meezan315.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan316: file(relativePath: { eq: "Meezan/Meezan316.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan317: file(relativePath: { eq: "Meezan/Meezan317.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan321: file(relativePath: { eq: "Meezan/Meezan321.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan322: file(relativePath: { eq: "Meezan/Meezan322.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      Meezan323: file(relativePath: { eq: "Meezan/Meezan323.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      Meezan324: file(relativePath: { eq: "Meezan/Meezan324.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      Meezan325: file(relativePath: { eq: "Meezan/Meezan325.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      Meezan326: file(relativePath: { eq: "Meezan/Meezan326.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      
    }
  `)
  let width = widthInfo()
  const { Meezan311, Meezan312, Meezan313, Meezan314, Meezan315, Meezan316, Meezan317, Meezan321, Meezan322, Meezan323, Meezan324, Meezan325, Meezan326 } = PictureData;

  return (
    <>

      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>There are two methods to pay your loan amount using Meezan Bank Limited.</span>
        </div>
        <div className={styles.foursamllPart}>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>“Meezan Bank” Mobile App</span>
          </div>
          <div className={styles.fourItemTitle}>
            <span className={styles.fourItemIcon}></span>
            <span className={styles.fourItemText}>Meezan Bank Website</span>
          </div>
          <div className={styles.height38}></div>
          {/* 01 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>Meezan Bank Mobile App</span>
          </div>
          <div className={styles.fourthredPart}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Go to the Meezan Bank App and tap the “Bills & Top up” option on your App.</span>
            </div>
            <Img
              className={styles.pict372}
              fluid={Meezan311.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>On the Bill Payment page, tap “Add Bills & Mobile Number”.</span>
            </div>
            <Img
              className={styles.pict492}
              fluid={Meezan312.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Type “1BILL” in the search bar and select “1BILL - INVOICES” from the search results.</span>
            </div>
            <Img
              className={styles.pict492}
              fluid={Meezan313.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Enter your Barwaqt “Token Number” and tap on “Done” button.</span>
            </div>
            <Img
              className={styles.pict462}
              fluid={Meezan314.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Enter the name of the beneficiary, review your payment details and tap “Add”.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={Meezan315.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Tap the “Pay Now” button to make the loan re-payment successful.</span>
            </div>
            <Img
              className={styles.pict330}
              fluid={Meezan316.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Congratulations! Your payment has been made successful.</span>
            </div>
            <Img
              className={styles.pict330}
              fluid={Meezan317.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          {/* 02 */}
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>Meezan Bank Website</span>
          </div>
        </div>
      </div>

      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`} >
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Go to the URL <a className={styles.href} href=" https://ebanking.meezanbank.com/AmbitRetailFrontEnd/login" target="_blank">  https://ebanking.meezanbank.com/AmbitRetailFrontEnd/login </a> and Login to your Meezan Bank account using your credentials.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan321.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>On the home page of your Meezan Bank website, click “Bills And Topup” from the menu list.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan322.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Type “1Bill” in the search bar and select “1BILL - INVOICES” from the search results.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan323.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>In the Characters Typed section, add the name of the beneficiary and click “Next”.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan324.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Once the Bill is registered, click the “Pay Now” button to proceed.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan325.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
      <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
        <span className={styles.fourItemIcon}></span>
        <span className={styles.fourItemText}>Review your payment details here and click “Next” to make the loan re-payment successful.</span>
      </div>
      <Img
        className={styles.pict1000}
        fluid={Meezan326.childImageSharp.fluid}
        alt="A corgi smiling happily"
      />
    </>

  )
}

export default Meezan;
