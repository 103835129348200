import * as React from "react"
import * as styles from '../../pages/css/oneBill.module.styl'
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image";
import { widthInfo } from "../../util/index.js"

const JazzCash = () => {
  const PictureData = useStaticQuery(graphql`
    query JazzCashQuery {
      JazzCash3: file(relativePath: { eq: "JazzCash/JazzCash3.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash4: file(relativePath: { eq: "JazzCash/JazzCash4.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash5: file(relativePath: { eq: "JazzCash/JazzCash5.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash7: file(relativePath: { eq: "JazzCash/JazzCash7.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash8: file(relativePath: { eq: "JazzCash/JazzCash8.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash61: file(relativePath: { eq: "JazzCash/JazzCash61.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash621: file(relativePath: { eq: "JazzCash/JazzCash621.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash622: file(relativePath: { eq: "JazzCash/JazzCash622.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,
      JazzCash623: file(relativePath: { eq: "JazzCash/JazzCash623.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      JazzCash624: file(relativePath: { eq: "JazzCash/JazzCash624.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      JazzCash625: file(relativePath: { eq: "JazzCash/JazzCash625.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
      JazzCash626: file(relativePath: { eq: "JazzCash/JazzCash626.png" }) {
        childImageSharp {
          fluid {
          ...GatsbyImageSharpFluid
          }
        }
      } ,  
    }
  `)
  let width = widthInfo()
  const { JazzCash3, JazzCash4, JazzCash5, JazzCash61, JazzCash7, JazzCash8, JazzCash622, JazzCash621, JazzCash62, JazzCash623, JazzCash624, JazzCash625, JazzCash626, } = PictureData;

  return (
    <>

      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}03</span>
          <span className={styles.contentTitleText}>Go to the JazzCash App and tap the search icon on the bottom right corner of your App.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={JazzCash3.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}04</span>
          <span className={styles.contentTitleText}>Type “1Bill” in the search bar and select “1Bill (Invoice and Voucher)” from the search results.</span>
        </div>
        <Img
          className={styles.pict481}
          fluid={JazzCash4.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}05</span>
          <span className={styles.contentTitleText}>Enter your Barwaqt “Token Number” and tap the “Continue” button.</span>
        </div>
        <Img
          className={styles.pict404}
          fluid={JazzCash5.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}06</span>
          <span className={styles.contentTitleText}>There are two options to pay your loan amount.</span>
        </div>
        <div className={styles.height38}></div>
        {/* 02 */}
        <div className={styles.foursamllPart} >
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>If you have sufficient amount in your “JazzCash Wallet” to pay your loan, tap the “Confirm Transaction” button.</span>
          </div>
          <Img
            className={styles.pict462}
            fluid={JazzCash61.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.secondTitle}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>If your amount is low in your JazzCash wallet, you can choose “Pay via another Debit Card”.</span>
          </div>
          <Img
            className={styles.pict320}
            fluid={JazzCash621.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>


        <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`} >
          <span className={styles.fourItemIcon}></span>
          <span className={styles.fourItemText}>In the Debit Cards option, tap “Add another debit card”.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={JazzCash622.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
          <span className={styles.fourItemIcon}></span>
          <span className={styles.fourItemText}>Enter your required debit card details and tap the “Continue” button.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={JazzCash623.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
          <span className={styles.fourItemIcon}></span>
          <span className={styles.fourItemText}>Enter your Barwaqt loan amount here and tap the “Continue” button.</span>
        </div>
        <Img
          className={styles.pict462}
          fluid={JazzCash624.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
          <span className={styles.fourItemIcon}></span>
          <span className={styles.fourItemText}>For the authentication purpose, your bank will ask your permission. Choose “Text” Or “E-Mail” to receive the verification code and tap the “CONTINUE” button.</span>
        </div>
        <Img
          className={styles.pict462}
          fluid={JazzCash625.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.marginLeft128} ${styles.fourItemTitle}`}>
          <span className={styles.fourItemIcon}></span>
          <span className={styles.fourItemText}>Enter your verification code and tap the “SUBMIT” button.</span>
        </div>
        <Img
          className={styles.pict450}
          fluid={JazzCash626.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}07</span>
          <span className={styles.contentTitleText}>Enter your JazzCash Wallet “MPIN” to authorize the transaction.</span>
        </div>
        <Img
          className={styles.pict450}
          fluid={JazzCash7.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={styles.contentTitle}>
          <span className={styles.contentTitleIcon}> {width > 1024 ? <span>STEP </span> : null}08</span>
          <span className={styles.contentTitleText}>Congratulations! Your loan amount has been paid. You will receive a confirmation SMS on your mobile phone. You can see your order has been settled on the Barwaqt App.</span>
        </div>
        <Img
          className={styles.pict320}
          fluid={JazzCash8.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
      </div>




    </>

  )
}

export default JazzCash;
